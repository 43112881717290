<template>
  <section class="table-style">
    <a-table
      :columns="recordsColumns"
      :data-source="tableData"
      :scroll="{ x: 300 }"
      :pagination="state.pagination"
      :loading="state.tableLoading"
      @change="handleTableChange"
      rowKey="documentId"
      ref="tableRef"
    >
      <!-- 序号 -->
      <template #id="{ index }">{{ (state.pagination.index - 1) * state.pagination.pageSize + index + 1 }}</template>
      <!-- 流程主题 -->
      <template #fileName="{ text }">
        <div class="text-box">
          <a-tooltip placement="bottomLeft" v-if="text?.length > 13">
            <template #title>
              <span>{{ text }}</span>
            </template>
            {{ text.slice(0, 12) }}...
          </a-tooltip>
          <span v-else>{{ text ?? '-' }}</span>
        </div>
      </template>
      <!-- 文件类型 -->
      <template #fileTypeName="{ text }">
        <div class="text-box">
          <a-tooltip placement="bottomLeft" v-if="text?.length > 13">
            <template #title>
              <span>{{ text }}</span>
            </template>
            {{ text.slice(0, 12) }}...
          </a-tooltip>
          <span v-else>{{ text ?? '-' }}</span>
        </div>
      </template>
      <!-- 申请人 -->
      <template #staffName="{ text }">
        <div class="text-box">
          <a-tooltip placement="bottomLeft" v-if="text?.length > 11">
            <template #title>
              <span>{{ text }}</span>
            </template>
            {{ text.slice(0, 10) }}...
          </a-tooltip>
          <span v-else>{{ text ?? '-' }}</span>
        </div>
      </template>
      <!-- 盖印人 -->
      <template #affixedStaffname="{ text }">
        <div class="text-box">
          <a-tooltip placement="bottomLeft" v-if="text?.length > 11">
            <template #title>
              <span>{{ text }}</span>
            </template>
            {{ text.slice(0, 10) }}...
          </a-tooltip>
          <span v-else>{{ text ?? '-' }}</span>
        </div>
      </template>
      <!-- 归档人 -->
      <template #archiveStaffname="{ text }">
        <div class="text-box">
          <a-tooltip placement="bottomLeft" v-if="text?.length > 11">
            <template #title>
              <span>{{ text }}</span>
            </template>
            {{ text.slice(0, 10) }}...
          </a-tooltip>
          <span v-else>{{ text ?? '-' }}</span>
        </div>
      </template>
      <!-- 印章名称 -->
      <template #sealName="{ text }">
        <div class="text-box">
          <a-tooltip placement="bottomLeft" v-if="text?.length > 10">
            <template #title>
              <span>{{ text }}</span>
            </template>
            {{ text.slice(0, 9) }}...
          </a-tooltip>
          <span v-else>{{ text ?? '-' }}</span>
        </div>
      </template>
      <!-- 业务类型 -->
      <template #processType="{ text }">
        <div class="text-box">
          <a-tooltip placement="bottomLeft" v-if="text">
            <span v-if="text == '1'">普通申请</span>
            <span v-if="text == '2'">自助申请</span>
          </a-tooltip>
          <span v-else>{{ '--' }}</span>
        </div>
      </template>
      <!-- 流程名称 -->
      <template #processName="{ text }">
        <div class="text-box">
          <a-tooltip placement="bottomLeft" v-if="text?.length > 13">
            <template #title>
              <span>{{ text }}</span>
            </template>
            {{ text.slice(0, 12) }}...
          </a-tooltip>
          <span v-else>{{ text ?? '-' }}</span>
        </div>
      </template>
      <template #action="{ record }">
        <a style="color:#1890FF" @click="check(record)">查看</a>
      </template>
    </a-table>
  </section>
</template>
<script setup>
import { reactive, defineProps, onMounted, ref, watch } from 'vue'
import { useRouter, useRoute, onBeforeRouteLeave } from 'vue-router'
import { recordsColumns } from './columns'
import { paginationParams } from '@/utils/constData'
// import { getSearchRecords } from '@/apis/recordsManage'
import { getArchiveList } from '@/apis/approval'
import moment from 'moment'
const router = useRouter()
const props = defineProps(['formState'])
const tableData = ref([])
const state = reactive({
  tableLoading: false,
  pagination: {
    ...paginationParams
  }
})
let params = reactive({})
// const searchData = reactive({});
const updatList = () => {
  let formState = { ...props.formState }
  let filename = {}
  filename[formState.filename] = formState.filevalue
  let personname = {}
  personname[formState.personname] = formState.personvalue
  let mentname = {}
  mentname[formState.mentname] = formState.deptList.map(item => item.departmentId)
  params = {
    ...filename,
    ...personname,
    ...mentname,
    fileTypeNames: formState.fileTypeName,
    startTime: formState.submitTime.length ? `${moment(formState.submitTime[0]).format('YYYY-MM-DD 00:00:00')}` : '', //开始时间
    endTime: formState.submitTime.length ? `${moment(formState.submitTime[1]).format('YYYY-MM-DD 23:59:59')}` : '', //结束时间
    pageSize: state.pagination.pageSize,
    pageIndex: state.pagination.current //
  }
  console.log('56666', params)
  getList()
}
const check = record => {
  console.log('调到详情页', record)
  router.push({
    path: '/archiveManage/detail',
    query: {
      id: record.id,
      type: 1
    }
  })
}
const getList = async () => {
  await getArchiveList(params).then(res => {
    tableData.value = res.data
    console.log(res)
    state.pagination.total = res.totalItem
    state.pagination.index = res.pageIndex
  })
}
const changeIndex = () => {
  console.log('changeIndex被触发')
  state.pagination.current = 1
  updatList()
}
const handleTableChange = ({ current, pageSize }) => {
  // 页码change改变
  state.dataSource = []
  state.pagination.current = current
  state.currentNum = current
  state.pagination.pageSize = pageSize
  updatList()
}
onMounted(() => {
  updatList()
})

defineExpose({
  updatList,
  changeIndex
})
</script>
<style></style>
